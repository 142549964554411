<template>
    <Toast/>
    <div class="surface-section">
        <div class="font-medium text-3xl text-900 mb-3 flex main-top">
            <!--<div style="height:50px;width:50px;border-radius:30px;overflow:hidden;display:inline-block;margin-right:10px;">
                <img :src="imageUrl" onerror="javascript:this.src='images/coachera-logo-sm.png'" class="mr-4" style="height:50px;">
            </div>-->
            <Image
                :src="imageUrl"
                :preview=true
                :imageStyle="{'border-radius': '50px', 'height': '40px'}"
                onerror="javascript:this.src='images/ludi_placeholder.png'"
            />
            <span class="profile-name">{{ profiledata.name }}</span>
            <div v-if="uid != -1"><Button type="button" icon="pi pi-comment" class="p-button-rounded p-button-outlined mr-2 ml-2" label="Chat" v-on:click="comment(profiledata.id)"></Button></div>
            <div v-if="uid == -1" style="margin-left:auto;"><Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2 ml-2" label="Edit" v-on:click="editProfile"></Button></div>
        </div>
        <!--<div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>-->
        <ul class="list-none p-0 m-0">
            <li v-if="uid == -1" class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">E-Mail</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.email }}</div>
                <!--<div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>-->
            </li>

            <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Country</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.country }}</div>
                <!--<div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>-->
            </li>

            <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Timezone preference</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.time_zone_pref }}</div>
                <!--<div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>-->
            </li>

            <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Needs</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <Chart type="radar" :data="radarData" :options="radarOptions" style="width: 100%" />
                </div>
            </li>

            <!--<li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Genre</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <Chip label="Crime" class="mr-2"></Chip>
                    <Chip label="Drama" class="mr-2"></Chip>
                    <Chip label="Thriller"></Chip>
                </div>
                <div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>
            </li>-->

            <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">About yourself</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.about }}</div>
                <!--<div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>-->
            </li>

            <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Your expectations</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.expectations }}</div>
                <!--<div class="w-6 md:w-2 flex justify-content-end">
                    <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                </div>-->
            </li>
        </ul>
    </div>

</template>

<script>
import UserService from '../service/UserService';

export default {
    props: {
        uid: {
            default: -1
        }
    },
    data() {
        return {
            profiledata: {},
            radarOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    r: {
                        beginAtZero: true,
                        angleLines: {
                            display: false
                        },
                        max: 5,
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            },
            radarData: {
                labels: ['Grow', 'Lead', 'Balance', 'Change', 'Escape', 'Reflect'],
                datasets: [
                    {
                        label: '',
                        backgroundColor: 'rgba(179,181,198,0.2)',
                        borderColor: 'rgba(179,181,198,1)',
                        pointBackgroundColor: 'rgba(179,181,198,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(179,181,198,1)',
                        data: [2.5, 2.5, 2.5, 2.5, 2.5, 2.5]
                    }
                ]
            },
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    mounted() {
        this.reload();
    },
    computed: {
        imageUrl() {
            return "images/" + this.profiledata.id + ".jpg";
        }
    },
    watch: {
        'uid': function (uid) {
            this.reload();
        }
    },
    methods: {
        reload() {
            this.userService.getUser(this.uid).then(data => {
                if (!data || data == null || typeof(data) === "undefined") {
                     this.$toast.add({severity:'error', summary: 'This profile does not exist.', life: 20000}); 
                } else {
                    this.profiledata = data;
                    this.radarData.datasets[0].data = this.profiledata.computed_vector;
                }
            });
        },
        comment(uid) {
            this.userService.saveConversation({"uid": uid}).then(data => {
                this.$router.push({ name: 'conversations', params: { uid: uid } });
            });
        },
        editProfile() {
            this.$router.push({ name: 'editprofile' });
        }
    }
};
</script>

<style lang="scss" scoped>
.surface-section {
    margin-top: 20px;
    width: 100%;
}
.main-top {
    display: flex;
    align-items: center;
}
.profile-name {
    margin-left: 10px;
}
.p-image {
    height: 40px;
}
.textarea {
    white-space: pre-wrap;
}
</style>
