<template>
    <div class="flex result-main">

        <Profile></Profile>

        <div class="justify-center">
            <Button label="Continue to platform" icon="pi pi-check" class="w-8" v-on:click="submit"></Button>
        </div>

    </div>
</template>

<script>
import Profile from "@/components/Profile";

export default {
    components: {Profile},
    methods: {
        submit(event) {
            this.$router.push("/search");
        },
    }
};
</script>

<style scoped>
.result-main {
    flex-direction: column;
}
.surface-section {
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
}
</style>
